import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';

export interface PaginatorChange {
  page: number;
  numberOfPages: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Output() change = new EventEmitter<PaginatorChange>();
  @Input() page: FormControl = new FormControl(0);
  @Input() total: FormControl = new FormControl(0);
  @Input() itemsPerPage: FormControl = new FormControl(0);
  @Input() maxPageToShow: FormControl = new FormControl(5);
  numberOfPages: FormControl = new FormControl(0);
  pages: number[] = [];

  constructor() {}

  ngOnInit(): void {
    this.createPages();
    merge(
      this.total.valueChanges,
      this.itemsPerPage.valueChanges
    ).subscribe({
      next: () => {
        this.numberOfPages.setValue(
          Math.ceil(this.total.value / this.itemsPerPage.value)
        );
        this.createPages();
      },
    });
  }

  setPage(p: number) {
    this.page.setValue(p);
    this.createPages();
    this.paginatorChange();
  }

  back() {
    if (this.page.value > 0) {
      this.page.setValue(this.page.value - 1);
      this.createPages();
      this.paginatorChange();
    }
  }

  forward() {
    if (this.page.value < this.numberOfPages.value - 1) {
      this.page.setValue(this.page.value + 1);
      this.createPages();
      this.paginatorChange();
    }
  }

  paginatorChange() {
    this.change.emit({
      page: this.page.value,
      numberOfPages: this.numberOfPages.value,
    });
  }

  createPages() {
    this.pages = [];
    for (let i = this.page.value - 3; i < this.numberOfPages.value; i++) {
      if (i >= 0 && this.pages.length < this.maxPageToShow.value) {
        this.pages.push(i);
      }
    }
    while (this.pages.length < 5 && this.pages[0] > 1) {
      this.pages.unshift(this.pages[0] - 1);
    }
    
    // verificando se ainda tem pagina anterior
    if (this.pages[0] > 2) {
      this.pages.unshift(-1);
      this.pages.unshift(1);
    }
    
    // verificando se ainda tem pagina posterior
    if (this.pages[this.pages.length - 1] < this.numberOfPages.value - 1) {
      this.pages.push(-1);
      this.pages.push(this.numberOfPages.value);
    }
  }
}

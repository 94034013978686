import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements AfterViewInit {
  @Input() show: boolean = false;
  @Input() margin: number = 0;
  @Input() radius: number = 0;
  @ViewChild('loadingContainer') loadingContainer!: ElementRef<HTMLDivElement>;
  parent!: HTMLElement;

  constructor(private readonly viewRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    this.parent = this.viewRef.element.nativeElement.parentElement;
  }

  // getStyle() {
  //   if (!this.parent) {
  //     return {};
  //   }
  //   const bounds = this.parent.getBoundingClientRect();
  //   return {
  //     top: `${bounds.top - this.margin}px`,
  //     left: `${bounds.left - this.margin}px`,
  //     width: `${bounds.width + (this.margin * 2)}px`,
  //     height: `${bounds.height + (this.margin * 2)}px`,
  //     borderRadius: `${this.radius}px`
  //   };
  // }
}

<section *ngIf="alertService.alert" (click)="close($event)">
  <div class="alert-container {{ alertService.alert.type }}">
    <div class="title">
      <h1>{{ alertService.alert.title }}</h1>
      <span class="material-icons">{{ getIcon() }}</span>
    </div>
    <div class="message">
      <ng-container *ngIf="alertService.alert.message.constructor === Array; else notArray">
        <p *ngFor="let message of alertService.alert.message">{{ message }}</p>
      </ng-container>
      <ng-template #notArray>
        <p>{{ alertService.alert.message }}</p>
      </ng-template>
    </div>
    <div class="actions">
      <app-button
        *ngIf="!alertService.alert.buttons; else hasButtons"
        label="OK"
        (click)="handleOK()"
      ></app-button>
      <ng-template #hasButtons>
        <app-button
          *ngFor="let button of alertService.alert.buttons"
          [label]="button.label"
          [color]="button.color"
          (click)="button.action()"
        ></app-button>
      </ng-template>
    </div>
  </div>
</section>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/shared/models/api';
import {
  IGetUserResponse,
  IPostUserRequest,
  IPostUserResponse,
  IPutBlockUserResponse,
  IPutUnblockUserResponse,
  IPutUserRequest,
  IPutUserResponse,
} from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<IResponse<IGetUserResponse[]>> {
    return this.http.get<IResponse<IGetUserResponse[]>>(
      `${environment.apiUrl}/Accounts/AccountUser/`
    );
  }

  postUser(body: IPostUserRequest): Observable<IResponse<IPostUserResponse>> {
    return this.http.post<IResponse<IPostUserResponse>>(
      `${environment.apiUrl}/Accounts/AccountUser`,
      body
    );
  }

  putUser(
    id: string,
    body: IPutUserRequest
  ): Observable<IResponse<IPutUserResponse>> {
    return this.http.put<IResponse<IPutUserResponse>>(
      `${environment.apiUrl}/Accounts/AccountUser/${id}`,
      body
    );
  }

  getUser(id: string): Observable<IResponse<IGetUserResponse>> {
    return this.http.get<IResponse<IGetUserResponse>>(
      `${environment.apiUrl}/Accounts/AccountUser/${id}`
    );
  }

  blockUser(id: string): Observable<IResponse<IPutBlockUserResponse>> {
    return this.http.put<IResponse<IPutBlockUserResponse>>(
      `${environment.apiUrl}/Accounts/AccountUser/${id}/Block`,
      {}
    );
  }

  unblockUser(id: string): Observable<IResponse<IPutUnblockUserResponse>> {
    return this.http.put<IResponse<IPutUnblockUserResponse>>(
      `${environment.apiUrl}/Accounts/AccountUser/${id}/Unblock`,
      {}
    );
  }
}

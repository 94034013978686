import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  MOBILE_BREAK,
  TABLET_BREAK,
} from 'src/app/shared/constants/responsiveness';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() tabletMenuClick = new EventEmitter();
  @Output() login = new EventEmitter();
  @Input() goLandingPage: boolean = false;
  @Input() showLogin: boolean = true;
  @Input() showMobileMenu: boolean = true;
  @Input() customerName!: string;
  screenWidth: number = window.innerWidth;
  MOBILE_BREAK = MOBILE_BREAK;
  TABLET_BREAK = TABLET_BREAK;

  constructor(public authService: AuthService, public router: Router) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  handleLogin() {
    this.login.emit();
  }

  goHome() {
    this.router.navigate([this.goLandingPage ? '/' : '/home']);
  }
}

import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-custom-component',
  templateUrl: './custom-component.component.html',
  styleUrls: ['./custom-component.component.scss'],
})
export class CustomComponentComponent implements OnInit {
  @Input() component: any;
  @Input() data: any;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.viewContainerRef.clear();
    const componentRef =
      this.viewContainerRef.createComponent(componentFactory);
    Object.keys(this.data).forEach((key) => {
      (<any>componentRef.instance)[key] = this.data[key];
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface MenuItem<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Output() itemAction = new EventEmitter<MenuItem<any>>();
  items!: Array<MenuItem<any>>;
  show: boolean = false;
  bounds!: DOMRect;

  public open(bounds: DOMRect, items: MenuItem<any>[]) {
    this.show = true;
    this.bounds = bounds;
    this.items = items;
  }

  handleClick(item: MenuItem<any>) {
    this.itemAction.emit(item);
    this.show = false;
  }

  handleBackground() {
    this.show = false;
  }

  getPosition() {
    if (this.bounds) {
      return {
        bottom: `${window.innerHeight - this.bounds.bottom}px`,
        right: `${window.innerWidth - this.bounds.left}px`,
      };
    }
    return {};
  }
}

<header>
  <div>
    <span>Transações de {{ transaction.date | date: "dd/MM/yyyy" }}</span>
  </div>
  <div>
    <span *ngIf="showBalance">Saldo inicial: {{ transaction.dayLimitBefore | currencyBRL }}</span>
  </div>
</header>
<main>
  <app-transaction-item
    *ngFor="let item of transaction.transactions"
    [transactionItem]="item"
    [hasActions]="hasActions"
    (action)="itemAction.emit($event)"
  ></app-transaction-item>
</main>
<footer>
  <span *ngIf="showBalance">Saldo final: {{ transaction.dayLimitAfter | currencyBRL }}</span>
</footer>

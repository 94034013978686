<form *ngIf="form" [formGroup]="form">
  <app-input
    label="Nome"
    placeholder="Nome"
    [control]="favoredName"
  ></app-input>
  <app-input
    placeholder="CPF/CNPJ"
    label="CPF/CNPJ"
    [control]="favoredTaxNumber"
    [mask]="'000.000.000-00||00.000.000/0000-00'"
  ></app-input>
  <app-radiogroup
    [items]="[
      { label: 'Pix', value: FavoredTypeEnum.Pix},
      { label: 'Agência e Conta', value: FavoredTypeEnum.Account}
    ]"
    class="pix-account-radio"
    [control]="favoredType"
    name="favoredPixAccount"
    [inline]="true"
  ></app-radiogroup>
  <app-radiogroup
    *ngIf="favoredType.value === FavoredTypeEnum.Pix"
    class="radio-pix-account"
    [items]="[
      { label: 'Documento', value: PixKeyOptionEnum.document },
      { label: 'Telefone', value: PixKeyOptionEnum.phone },
      { label: 'E-mail', value: PixKeyOptionEnum.email },
      { label: 'Aleatória', value: PixKeyOptionEnum.random }
    ]"
    [control]="favoredPixKeyType"
    name="favoredPixKeyType"
    [inline]="true"
  ></app-radiogroup>
  <app-input
    [control]="favoredPixKey"
    label="Chave Pix"
    placeholder="Chave Pix"
    *ngIf="favoredType.value === FavoredTypeEnum.Pix"
    [mask]="getMask()"
  ></app-input>
  <app-radiogroup
    *ngIf="favoredType.value === FavoredTypeEnum.Account"
    class="radio-account-type"
    [inline]="true"
    [items]="accountTypes"
    [control]="favoredAccountType"
    name="account-type"
  ></app-radiogroup>
  <app-select
    label="Banco"
    [control]="favoredBank"
    [options]="bankList"
    *ngIf="favoredType.value === FavoredTypeEnum.Account"
  ></app-select>
  <div class="account-info">
    <app-input
      placeholder="Agência"
      label="Agência"
      [control]="favoredBranch"
      mask="9999"
      *ngIf="favoredType.value === FavoredTypeEnum.Account"
    ></app-input>
    <app-input
      placeholder="Conta"
      label="Conta"
      [control]="favoredAccountNumber"
      mask="99999999999999999999"
      *ngIf="favoredType.value === FavoredTypeEnum.Account"
    ></app-input>
    <app-input
      class="digit"
      placeholder="0"
      label="Dígito"
      [control]="favoredDigit"
      mask="A"
      *ngIf="favoredType.value === FavoredTypeEnum.Account"
    ></app-input>
    <ng-content></ng-content>
  </div>
</form>

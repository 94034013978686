<app-alert></app-alert>
<app-confirm></app-confirm>
<app-header
  #header
  *ngIf="showHeader()"
  [customerName]="customerName"
  (tabletMenuClick)="toggleTabletMenu()"
></app-header>
<app-sidenav
  *ngIf="showSidenav(); else hideSidenav"
  (close)="closeSidenavMenu()"
>
  <router-outlet></router-outlet>
</app-sidenav>
<ng-template #hideSidenav>
  <router-outlet></router-outlet>
</ng-template>

<section>
  <input
    #input
    class="slider"
    type="range"
    [formControl]="control"
    [min]="min"
    [max]="max"
  />
</section>

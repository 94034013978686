import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormControlStatus } from '@angular/forms';
import { InputmaskOptions } from '@ngneat/input-mask';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() iconLeft: boolean = false;
  @Input() control!: FormControl;
  @Input() mask?: string;
  @Input() inputMask?: InputmaskOptions<any>;
  @Input() type?: 'currency' | 'date' | 'email' | 'password';
  @Input() disabled: boolean = false;
  @Input() showErrors: boolean = true;
  @Input() mode?: 'secondary';
  @Output() paste = new EventEmitter<ClipboardEvent>();
  errors: string[] = [];
  showPassword: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.control.statusChanges.subscribe({
      next: (controlStatus) => {
        this.handleErrors(controlStatus);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled } = changes;
    if (disabled) {
      if (disabled.currentValue) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  handleErrors(controlStatus: FormControlStatus) {
    const errors: string[] = [];
    if (controlStatus === 'INVALID' && this.control.dirty) {
      if (this.control.hasError('email')) {
        errors.push('Email inválido');
      } else if (this.control.hasError('minlength')) {
        errors.push('Campo muito curto');
      } else if (this.control.hasError('maxlength')) {
        errors.push('Campo muito longo');
      } else if (this.control.hasError('required')) {
        errors.push('Campo obrigatório');
      } else if (this.control.hasError('passwordStrength')) {
        errors.push(
          'A senha deve possuir 8 dígitos, letras maiúsculas e minusculas, numero e caractere especial'
        );
      } else if (errors.length === 0) {
        errors.push('Valor inválido');
      }
    }
    this.errors = errors;
  }

  openDate(event: MouseEvent) {
    if (!this.disabled) {
      const el: HTMLDivElement = event.target as HTMLDivElement;
      const input: any = el.nextElementSibling;
      input.showPicker();
    }
  }
}

<app-button (click)="download()" label="Baixar PDF"></app-button>
<section id="pdf">
  <header>
    <img src="assets/images/logo.svg" alt="">
    <span>{{ now | date:'dd/MM/YYY' }}</span>
  </header>
  <main>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </main>
</section>

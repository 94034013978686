declare let html2pdf: any;

import { Component } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-pdf-template',
  templateUrl: './pdf-template.component.html',
  styleUrls: ['./pdf-template.component.scss'],
})
export class PdfTemplateComponent {
  now = new Date().toISOString();

  download() {
    // const doc = new jsPDF({
    //   format: 'a4',
    // });
    const pdf = document.getElementById('pdf');
    
    // if (pdf) {
    //   html2canvas(pdf).then((canvas) => {
    //     var imgData = canvas.toDataURL('image/jpeg', 1.0);
    //     var pdf = new jsPDF({
    //       format: [210, 297],
    //       orientation: 'p',
    //       unit: 'mm',
    //     });
    //     //'p', 'mm', [210, 297]
    //     pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
    //     pdf.save('screen-3.pdf');
    //   });
    // }
  }
}

<div class="breadcrumb-container">
  <span
    class="material-icons breadcrumb-actions"
    (click)="router.navigate(['/'])"
    >home</span
  >
  <span class="material-icons breadcrumb-chevron"> chevron_right </span>
  <ng-container *ngFor="let step of steps; let last = last">
    <span class="breadcrumb-actions" (click)="goTo(step)">
      {{ step.label }}
    </span>
    <span *ngIf="!last" class="material-icons breadcrumb-chevron">
      chevron_right
    </span>
  </ng-container>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { delay, Observable, of, throwError } from 'rxjs';
import { ConfirmService } from 'src/app/core/services/confirm.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { environment } from 'src/environments/environment';
import { labelJwt } from '../constants/jwt';
import { IResponse } from '../models/api';
import {
  IPostContactRequest,
  IPostContactResponse,
  IPostGenerateMfaResponse,
  IPostLoginMfaResponse,
  IPostLoginResponse,
  IPostValidateMfaResponse,
  IPutChangePasswordResponse,
  IPutRecoverPasswordResponse,
  IPutResetPasswordResponse,
} from '../models/auth';
import { IGetUserResponse } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedUser!: IGetUserResponse;

  constructor(
    private router: Router,
    private http: HttpClient,
    private confirmService: ConfirmService,
    private jwtService: JwtService
  ) {}

  login(
    taxNumber: string,
    password: string
  ): Observable<IResponse<IPostLoginResponse>> {
    return this.http.post<IResponse<IPostLoginResponse>>(
      `${environment.apiAuthUrl}/Auth/Login`,
      {
        taxNumber,
        password,
      }
    );
  }

  loginMfa(
    taxNumber: string,
    mfaToken: string
  ): Observable<IResponse<IPostLoginMfaResponse>> {
    return this.http.post<IResponse<IPostLoginMfaResponse>>(
      `${environment.apiAuthUrl}/Auth/LoginMFA`,
      {
        taxNumber,
        mfaToken,
      }
    );
  }

  generateMfa(): Observable<IResponse<IPostGenerateMfaResponse>> {
    return this.http.post<IResponse<IPostGenerateMfaResponse>>(
      `${environment.apiAuthUrl}/Auth/GenerateMFA`,
      {}
    );
  }

  validateMfa(
    mfaToken: string
  ): Observable<IResponse<IPostValidateMfaResponse>> {
    return this.http.post<IResponse<IPostValidateMfaResponse>>(
      `${environment.apiAuthUrl}/Auth/ValidateMFA`,
      { mfaToken }
    );
  }

  recoverPassword(
    taxNumber: string
  ): Observable<IResponse<IPutRecoverPasswordResponse>> {
    return this.http.put<IResponse<IPutRecoverPasswordResponse>>(
      `${environment.apiAuthUrl}/Auth/RecoverPassword`,
      {
        taxNumber,
      }
    );
  }

  resetPassword(
    changePasswordTicket: string,
    newPassword: string,
    confirmNewPassword: string
  ): Observable<IResponse<IPutResetPasswordResponse>> {
    return this.http.put<IResponse<IPutResetPasswordResponse>>(
      `${environment.apiAuthUrl}/Auth/ResetPassword`,
      {
        changePasswordTicket,
        newPassword,
        confirmNewPassword,
      }
    );
  }

  changePassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Observable<IResponse<IPutChangePasswordResponse>> {
    return this.http.put<IResponse<IPutChangePasswordResponse>>(
      `${environment.apiAuthUrl}/Auth/ChangePassword`,
      {
        currentPassword,
        newPassword,
        confirmNewPassword,
      }
    );
  }

  logoutConfirm() {
    this.confirmService.open({
      title: 'Atenção',
      message: 'Deseja realmente sair?',
      click: (exit) => {
        if (exit) {
          this.logout();
        }
      },
    });
  }

  logout() {
    this.removeJwt();
    this.router.navigate(['/']);
  }

  setJwt(jwt: string) {
    sessionStorage.setItem(labelJwt, jwt);
  }

  removeJwt() {
    sessionStorage.removeItem(labelJwt);
  }

  isLogged(): boolean {
    return this.jwtService.hasJwt();
  }

  contact(
    data: IPostContactRequest
  ): Observable<IResponse<IPostContactResponse>> {
    return this.http.post<IResponse<IPostContactResponse>>(
      `${environment.apiAuthUrl}/Contact`,
      data
    );
  }
}

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface SelectOption {
  label: string;
  value: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @ViewChild('select') select!: ElementRef;
  @ViewChild('boxContainer') boxContainer!: ElementRef;
  @ViewChild('inputSearch') inputSearch!: ElementRef;
  @Input() label!: string;
  @Input() control!: FormControl;
  @Input() placeholder: string = 'Selecione';
  @Input() options: SelectOption[] = [];
  @Input() hideErrors?: boolean = false;
  @Input() disabled: boolean = false;
  selected!: SelectOption;
  searchControl = new FormControl('');
  errors: string[] = [];
  boxOpen: boolean = false;
  filteredOptions: SelectOption[] = [];
  openTime!: number;

  constructor() {}

  ngOnInit(): void {
    const main = document.querySelector('.main-container') as HTMLElement;
    main.addEventListener('scroll', () => {
      this.boxOpen = false;
    });
    this.searchControl.valueChanges.subscribe({
      next: () => {
        this.filteredOptions = this.options.filter((o) =>
          o.label.toLowerCase().includes(this.searchControl.value.toLowerCase())
        );
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { options } = changes;
    if (options.currentValue) {
      this.filteredOptions = options.currentValue;
    }
  }

  selectOption(option: SelectOption) {
    this.control.setValue(option.value);
    this.boxOpen = false;
  }

  getLabel() {
    const option = this.options.find(o => o.value === this.control.value);
    return option ? option.label : ''
  }

  openBox() {
    this.boxOpen = true;
    this.openTime = new Date().getTime();
    setTimeout(() => {
      this.inputSearch.nativeElement.focus()
    }, 100);
  }

  checkClick(event: MouseEvent) {
    if (this.boxContainer && this.openTime + 100 < new Date().getTime()) {
      const elementFromPoint = document.elementFromPoint(
        event.clientX,
        event.clientY
      );
      if (elementFromPoint === this.boxContainer.nativeElement) {
        this.boxOpen = false;
      }
    }
  }

  getBoxStyle() {
    let css: any = {};
    if (this.select) {
      const bounds = this.select.nativeElement.getBoundingClientRect();
      if (
        bounds.left < window.innerWidth / 2 &&
        bounds.top < window.innerHeight / 2
      ) {
        // LEFT TOP
        css.top = `${bounds.top + bounds.height}px`;
        css.left = `${bounds.left}px`;
      } else if (
        bounds.left >= window.innerWidth / 2 &&
        bounds.top < window.innerHeight / 2
      ) {
        // RIGHT TOP
        css.top = `${bounds.top + bounds.height}px`;
        css.right = `${window.innerWidth - bounds.right}px`;
      } else if (
        bounds.left < window.innerWidth / 2 &&
        bounds.top >= window.innerHeight / 2
      ) {
        // LEFT BOTTOM
        css.bottom = `${window.innerHeight - bounds.bottom + bounds.height}px`;
        css.left = `${bounds.left}px`;
      } else if (
        bounds.left >= window.innerWidth / 2 &&
        bounds.top >= window.innerHeight / 2
      ) {
        // RIGHT BOTTOM
        css.bottom = `${window.innerHeight - bounds.bottom + bounds.height}px`;
        css.right = `${window.innerWidth - bounds.right}px`;
      }
      css.minWidth = `${bounds.width}px`;
    } else {
      css.display = 'none';
    }
    return css;
  }
}

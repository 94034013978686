import { NgModule } from '@angular/core';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { AlertComponent } from './components/alert/alert.component';
import { AlertService } from './services/alert.service';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConfirmService } from './services/confirm.service';
import { JwtService } from './services/jwt.service';
@NgModule({
  declarations: [SidenavComponent, BreadcrumbComponent, AlertComponent, ConfirmComponent],
  exports: [SidenavComponent, BreadcrumbComponent, AlertComponent, ConfirmComponent],
  imports: [SharedModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    AlertService,
    ConfirmService,
    JwtService
  ],
})
export class CoreModule {}

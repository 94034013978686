<section
  [class.active]="false"
  [class.has-voucher]="transactionItem.hasVoucher && hasActions"
  (click)="handleAction(transactionItem)"
>
  <div class="user">
    <ng-container [ngSwitch]="transactionItem.processingCode">
      <div
        *ngSwitchCase="1000"
        class="initials"
        title="{{ PixoutTypeEnum[transactionItem.typeCode] }}"
      >
        {{ PixoutTypeEnum[transactionItem.typeCode] | displayInitials }}
      </div>
      <div
        *ngSwitchCase="1010"
        class="initials"
        title="{{ PixinTypeEnum[transactionItem.typeCode] }}"
      >
        {{ PixinTypeEnum[transactionItem.typeCode] | displayInitials }}
      </div>
      <div
        *ngSwitchDefault
        class="initials"
        title="{{ getTitle(transactionItem) }}"
      >
        {{ getTitle(transactionItem) | displayInitials }}
      </div>
    </ng-container>
  </div>
  <div class="info">
    <span class="title">
      {{ getTitle(transactionItem) }}
    </span>
    <span class="subtitle">
      {{ getSubtitle(transactionItem) | titlecase }}
    </span>
    <span class="date"
      >{{ transactionItem.date | date: "dd/MM/YYYY" }} às
      {{ transactionItem.date | date: "HH:mm:ss" }}</span
    >
    <span class="value">{{
      transactionItem.isCredit
        ? currencyBRLPipe.transform(transactionItem.value)
        : currencyBRLPipe.transform(transactionItem.value * -1)
    }}</span>
  </div>
  <div class="action" *ngIf="transactionItem.hasVoucher && hasActions">
    <span class="material-icons">chevron_right</span>
  </div>
</section>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { JwtService } from 'src/app/core/services/jwt.service';
import { environment } from 'src/environments/environment';
import {
  IGetAccountLimitResponse,
  IGetAccountResponse,
  IGetAccountsResponse,
  IGetLoggedAccountResponse,
  IPostSelectAccountResponse,
} from '../models/account';
import { IResponse } from '../models/api';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private jwtService: JwtService) {}

  getLoggedAccount(): Observable<IResponse<IGetLoggedAccountResponse>> {
    return this.http.get<IResponse<IGetLoggedAccountResponse>>(
      `${environment.apiUrl}/Accounts/Account/GetLoggedAccount`
    );
  }

  getAccount(accountId: string): Observable<IResponse<IGetAccountResponse>> {
    return this.http.get<IResponse<IGetAccountResponse>>(
      `${environment.apiUrl}/Accounts/Account/${accountId}`
    );
  }

  getLimit(): Observable<IResponse<IGetAccountLimitResponse>> {
    return this.http.get<IResponse<IGetAccountLimitResponse>>(
      `${environment.apiUrl}/Accounts/Account/Limit`
    );
  }

  getAccounts(): Observable<IResponse<IGetAccountsResponse[]>> {
    return this.http.get<IResponse<IGetAccountsResponse[]>>(
      `${environment.apiUrl}/Accounts/Account/ListAccountsByID`
    );
  }

  postSelectAccount(
    CustomerId: string
  ): Observable<IResponse<IPostSelectAccountResponse>> {
    return this.http.post<IResponse<IPostSelectAccountResponse>>(
      `${environment.apiUrl}/Accounts/Account/SetCustomerByID`,
      { CustomerId }
    );
  }
}

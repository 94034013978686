import { Component, ComponentFactoryResolver, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';

interface TableColumnManuItem {
  label: string;
  click: (item: any) => void;
}

type TableColumnType =
  | 'icon'
  | 'icon-edit'
  | 'menu'
  | 'date'
  | 'datetime'
  | 'currency'
  | 'phone'
  | 'cpf'
  | 'link'
  | 'component';

export interface TableColumn {
  label: string;
  field: string;
  type?: TableColumnType;
  gridColumn?: string;
  click?: (item: any, element?: PointerEvent) => any;
  menuItems?: TableColumnManuItem[];
  custom?: (item: any, row?: any) => any;
  component?: any,
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() rows: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() loading: boolean = false;
  @Input() hasSearch: boolean = false;
  @Input() widthColumnEqual: boolean = false;
  @ViewChild('tableBody') tableBody!: ElementRef;

  selectAll = new FormControl(false);

  constructor(public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {}

  handleColumnClick(row: any, col: TableColumn, el: any) {
    if (col.click) {
      col.click(row, el as PointerEvent);
    }
  }

  getRowStyle() {
    if (this.widthColumnEqual) {
      return {
        gridTemplateColumns: `repeat(${this.columns.length}, minmax(150px, auto))`,
          // this.columns
          // .map((c) => `calc(${Math.floor(100 / this.columns.length)}% - 5px)`)
          // .join(' '),
      };
    }
    let templateColumns: string[] = [];
    this.columns.forEach((col) => {
      templateColumns.push(col.gridColumn ? col.gridColumn : '1fr');
    });
    return {
      gridTemplateColumns: templateColumns.join(' '),
    };
  }

  getHeaderRowStyle() {
    if (this.widthColumnEqual) {
      return this.getRowStyle();
    }
    if (this.tableBody) {
      const _rows = this.tableBody.nativeElement.children as HTMLCollection;
      if (_rows.length) {
        const _row = _rows.item(0);
        const _cols = _row?.children as HTMLCollection;
        if (_cols.length) {
          let templateColumns: string[] = [];
          for (let i = 0; i < _cols.length; i++) {
            const _col = _cols[i];
            templateColumns.push(`${_col.getBoundingClientRect().width}px`);
          }
          return {
            gridTemplateColumns: templateColumns.join(' '),
          };
        }
      }
    }
    return this.getRowStyle();
  }

  getComponent() {
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    // this.viewContainerRef.clear();

    // const componentRef = this.viewContainerRef.createComponent(componentFactory);
    // (<DynamicComponentRef>componentRef.instance).data = this.data;
  }
}

import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TABLET_BREAK } from 'src/app/shared/constants/responsiveness';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JwtService } from '../../services/jwt.service';

export interface SidenavMenu {
  label: string;
  route: string[];
  icon: string;
  isOwner: boolean;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Output() close = new EventEmitter();
  tabletMenuOpen: boolean = false;
  TABLET_BREAK = TABLET_BREAK;
  menu: SidenavMenu[] = [
    { label: 'Home', route: ['/home'], icon: 'home', isOwner: false },
    {
      label: 'Minha Conta',
      route: ['/my-account'],
      icon: 'account_balance_wallet',
      isOwner: false,
    },
    {
      label: 'Saldo e Extrato',
      route: ['/balance'],
      icon: 'currency_exchange',
      isOwner: false,
    },
    { label: 'Pagamentos', route: ['/payments'], icon: 'payments', isOwner: false },
    { label: 'Transferências', route: ['/transfer'], icon: 'sync_alt', isOwner: false },
    { label: 'Pix', route: ['/pix'], icon: 'pix', isOwner: false },
    { label: 'Usuários', route: ['/user'], icon: 'person_add', isOwner: true },
    {
      label: 'Comprovantes',
      route: ['/comprovantes'],
      icon: 'receipt',
      isOwner: false,
    },
     {
       label: 'Aprovações',
       route: ['/approvals'],
       icon: 'checklist',
       isOwner: false,
     },
    // { label: 'Contatos', route: ['/client'], icon: 'contacts', isOwner: false },
  ];
  menuActive: string = 'home';
  screenWidth: number = window.innerWidth;

  constructor(
    public router: Router,
    public jwtService: JwtService,
    public authService: AuthService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  goTo(item: SidenavMenu) {
    this.menuActive = item.icon;
    this.router.navigate(item.route);
    if (this.screenWidth < TABLET_BREAK) {
      this.close.emit();
    }
  }
}

<section #select class="slender-select" (click)="openBox()">
  <span *ngIf="label">{{ label }}</span>
  <div class="container">
    <div *ngIf="control.value; else showPlaceholder">
      {{ getLabel()}}
    </div>
    <ng-template #showPlaceholder
      ><span class="placeholder">
        {{ placeholder }}
      </span></ng-template
    >
    <span class="material-icons">expand_more</span>
  </div>
  <div *ngIf="!hideErrors" class="error-container">
    <span *ngFor="let error of errors">{{ error }}</span>
  </div>
</section>
<div class="select-dropdown"></div>
<div
  class="box-container"
  #boxContainer
  *ngIf="boxOpen"
  (click)="checkClick($event)"
>
  <div class="box" [ngStyle]="getBoxStyle()">
    <div *ngIf="options.length === 0; else hasOptions" class="no-item">
      Nenhuma opção
    </div>
    <ng-template #hasOptions>
      <div class="search">
        <input type="text" #inputSearch [formControl]="searchControl" />
      </div>
      <div class="options">
        <div
          class="option"
          *ngFor="let option of filteredOptions"
          (click)="selectOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </ng-template>
  </div>
</div>
